.plan-row {
  @apply grid row-span-1 grid-cols-3 w-full gap-2 max-w-full sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 sm:gap-4;
}

.plan-row .cat-col {
  @apply col-span-3 sm:col-span-1 md:col-span-2 lg:col-span-3;
}

.plan-row .active-header {
  @apply relative z-10;
}

.plan-row .active-header::after {
  content: "";
  @apply absolute bg-primary h-5 w-5 -z-10 left-[50%] translate-x-[-50%] origin-center rotate-45 -bottom-2;
}

.plan-row .active img {
  filter: invert(0.4) sepia(1) saturate(2) hue-rotate(90deg);
}
