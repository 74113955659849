@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gradient-direction: 180deg;
  --gradient-black: #000000ff 0%;
  --gradient-white: #ff000000 100%;
}

@font-face {
  font-family: "sierraflixx-sans";
  src: url(../static/fonts/Poppins-Regular.ttf) format("truetype"),
    url(../static/fonts/Poppins-Black.ttf) format("truetype"),
    url(../static/fonts/Poppins-BlackItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-Bold.ttf) format("truetype"),
    url(../static/fonts/Poppins-BoldItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-ExtraBold.ttf) format("truetype"),
    url(../static/fonts/Poppins-ExtraBoldItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-ExtraLight.ttf) format("truetype"),
    url(../static/fonts/Poppins-ExtraLightItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-Italic.ttf) format("truetype"),
    url(../static/fonts/Poppins-Light.ttf) format("truetype"),
    url(../static/fonts/Poppins-LightItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-Medium.ttf) format("truetype"),
    url(../static/fonts/Poppins-MediumItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-SemiBold.ttf) format("truetype"),
    url(../static/fonts/Poppins-SemiBoldItalic.ttf) format("truetype"),
    url(../static/fonts/Poppins-Thin.ttf) format("truetype"),
    url(../static/fonts/Poppins-ThinItalic.ttf) format("truetype");
}

@layer base {
  * {
    text-size-adjust: 100% !important;
  }
  html {
    @apply transition-all duration-150 ease-linear;
  }

  body {
    @apply bg-black text-[#e5e5e5] min-w-full sm:tracking-wide overflow-x-hidden overflow-y-auto;
    font-size: 14px;
    font-family: "sierraflixx-sans", "Trebuchet MS", "Lucida Sans Unicode",
      "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  }

  /* header {
    @apply bg-black top-0 z-50 flex w-full items-center justify-between transition-all default-padding space-x-4;
  } */

  nav {
    @apply bg-black top-0 z-50 flex w-full items-center justify-between transition-all default-padding space-x-4;
  }

  section {
    @apply text-gray-300;
    --section-padding: 3rem;
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }

  section.bordered-top {
    border-top: 0.56rem solid #222;
  }

  section.bordered-bottom {
    border-bottom: 0.56rem solid #222;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .default-padding {
    @apply px-4 py-3 md:px-8 lg:px-14 lg:py-4;
  }

  .header-link {
    @apply cursor-pointer text-sm font-light text-[#e5e5e5] transition duration-[.4s] hover:text-[#b3b3b3];
  }

  .bg-gradient-top {
    --gradient-direction: 180deg;
  }

  .bg-gradient-left {
    --gradient-direction: 90deg;
  }

  .bg-gradient-right {
    --gradient-direction: -90deg;
  }

  .bg-gradient-bottom {
    --gradient-direction: 0deg;
  }

  .bg-gradient-top,
  .bg-gradient-left,
  .bg-gradient-right,
  .bg-gradient-bottom {
    background-size: 100% 100%;
    background-position: 0px 0px;
    background: -moz-linear-gradient(
      var(--gradient-direction),
      var(--gradient-black),
      var(--gradient-white)
    );
    background: -webkit-linear-gradient(
      var(--gradient-direction),
      var(--gradient-black),
      var(--gradient-white)
    );
    background: linear-gradient(
      var(--gradient-direction),
      var(--gradient-black),
      var(--gradient-white)
    );
  }

  .rounded-button {
    @apply !rounded-[100%] border p-1 w-5 h-5 sm:h-10 sm:w-10 sm:p-2 active:!rounded-[100%] focus:!rounded-[100%];
  }

  .banner-button {
    @apply flex space-x-2 items-center !rounded tracking-wider font-semibold transition hover:opacity-[0.85] focus:!rounded active:!rounded px-1.5 sm:px-4 py-1.5 md:py-2.5 md:px-7;
  }

  .banner-button span {
    @apply text-xs sm:text-sm md:text-base;
  }

  .banner-button .icon {
    @apply text-sm sm:text-[1.5rem];
  }

  .hover-dropdown .icon,
  .hover-drop .icon {
    @apply origin-center duration-200 ease-linear;
  }

  .hover-drop:hover .dropdown-menu {
    @apply visible block;
  }

  .hover-drop:hover .dropdown-toggle::after,
  .hover-drop:hover .icon {
    @apply rotate-180;
  }

  .dropdown-toggle {
    @apply flex items-center gap-2;
  }

  .dropdown-toggle::after {
    content: "";
    width: 0.7rem;
    height: 0.7rem;
    border: 0.15rem solid #e5e5e5;
    border-left: none;
    border-top: none;
    --transform-action: rotate(45deg);
    transform: var(--transform-action);
    -moz-transform: var(--transform-action);
    -webkit-transform: var(--transform-action);
    -o-transform: var(--transform-action);
    -ms-transform: var(--transform-action);
    @apply transition-all;
  }

  .slider-arrow {
    @apply absolute top-0 opacity-0 bottom-0 z-40 min-h-full m-auto cursor-pointer items-center hidden md:flex transition  group-hover:opacity-100;
  }

  .slider-arrow:hover .arrow {
    @apply scale-125;
  }

  .slider-arrow .arrow {
    @apply h-9 w-9 transition duration-200;
  }

  .cover {
    @apply relative py-16 w-screen max-w-full min-h-[25rem] sm:min-h-screen max-h-screen object-cover;
  }

  .bg-radial-gradient {
    background: radial-gradient(#0004 0%, #000c 90%);
  }

  .letter-spaced {
    letter-spacing: 0.2rem;
  }

  .bg-black-50 {
    background-color: #000c !important;
  }

  .hover-underline:hover {
    text-decoration: underline;
  }

  .img-cover::before {
    content: "";
    @apply left-0 top-0 absolute w-full h-full bg-transparent;
  }

  .section-grid {
    @apply grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-4 md:gap-8 text-center md:text-start default-padding py-0 md:max-w-[91%] items-center;
  }

  .section-content {
    @apply flex items-center justify-center;
  }

  .section-header {
    @apply text-2xl !leading-tight sm:text-4xl md:text-[2.5rem] lg:text-5xl font-semibold;
  }

  .profile-img {
    @apply invert-[8%] saturate-[8%] mix-blend-hard-light bg-red rounded;
  }

  .billing-table {
    @apply min-w-full text-left border-collapse border border-gray-200;
  }
  .billing-table th {
    @apply border-b border-gray-600 font-thin text-start;
  }
  .billing-table td {
    @apply border-b border-gray-400 py-1;
  }

  @media (min-height: 768px) {
    .cover {
      max-height: 75.45vh !important;
    }
  }
}

.slider-rotated {
  transform: translate(-50%, -50%) rotate(-90deg);
  position: absolute;
  top: -70px;
  left: 0px;
  margin-left: 20px;
}

.show {
  position: relative;
}

/* smooth change play & pause button */

.play-pause-btn {
  -webkit-clip-path: polygon(
    0 0,
    50% 25%,
    50% 75%,
    50% 75%,
    50% 25%,
    100% 50%,
    100% 50%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    50% 25%,
    50% 75%,
    50% 75%,
    50% 25%,
    100% 50%,
    100% 50%,
    0 100%
  );
}

.play-pause-btn.playing {
  -webkit-clip-path: polygon(
    0 0,
    40% 0,
    40% 100%,
    60% 100%,
    60% 0,
    100% 0,
    100% 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    40% 0,
    40% 100%,
    60% 100%,
    60% 0,
    100% 0,
    100% 100%,
    0 100%
  );
}

/* style video in fullscreen mode */

.fullscreen {
  max-width: 100%;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  border-radius: 0px !important;
}

/* change icon volume with increase & decrease volume */

.high-volume,
.low-volume,
.mute-volume {
  display: none;
}

.container-video[data-volume-level="high"] .high-volume {
  display: block;
}

.container-video[data-volume-level="low"] .low-volume {
  display: block;
}

.container-video[data-volume-level="muted"] .mute-volume {
  display: block;
}

.speed-rate li.active {
  background-color: rgb(29, 29, 120);
  color: rgb(233, 245, 209);
}

/* cover for button when video is very bright */

/*.backBetter::after {*/
/*  content: "";*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  z-index: -1;*/
/*  position: absolute;*/
/*  height: 100%;*/
/*  border-radius: 10px;*/
/*  pointer-events: none;*/
/*  !*background: linear-gradient(to top, rgb(130 16 16 / 50%), #0b49ed00);*!*/
/*  transform: translateX(-9px);*/
/*}*/

/* subtitle or caption custom */

video::cue {
  color: rgb(0, 0, 0);
  background-color: transparent;
  text-shadow: #d0ff00 1px 1px 3px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  line-height: 20px;
  position: absolute;
  bottom: 20px;
  transform: translateY(-300px) !important;
}

video::-moz-media-text-track-display-backdrop {
  background-color: rgb(243, 247, 0) !important;
  overflow: visible !important;
}

/* subtitle for  mozilla browser */

input[type="range"] {
  background-color: transparent;
}

input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 5px;
  -moz-appearance: none;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 12px;
  border-radius: 50px;
  background-color: lime;
  color: aqua;
  -moz-appearance: none;
}

.before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0::before {
  content: var(--tw-content);
  left: 0px;
}

.before\:top-0::before {
  content: var(--tw-content);
  top: 0px;
}

.before\:bottom-0::before {
  content: var(--tw-content);
  bottom: 0px;
}

.before\:right-\[calc\(100\%-var\(--preview\)\)\]::before {
  content: var(--tw-content);
  right: calc(100% - var(--preview));
}

.before\:hidden::before {
  content: var(--tw-content);
  display: none;
}

.before\:bg-main-light\/30::before {
  content: var(--tw-content);
  background-color: rgb(251 197 49 / 0.3);
}

.before\:content-\[\'\'\]::before {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.after\:top-0::after {
  content: var(--tw-content);
  top: 0px;
}

.after\:bottom-0::after {
  content: var(--tw-content);
  bottom: 0px;
}

.after\:right-\[calc\(100\%-var\(--progress\)\)\]::after {
  content: var(--tw-content);
  right: calc(100% - var(--progress));
}

/* .after\:bg-main-light::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(251 197 49 / var(--tw-bg-opacity));
} */

.after\:bg-main-light::after {
  background-color: green;
}

.bg-white {
  background-color: white;
}

.text-black {
  color: black;
}

.bg-black {
  background-color: black;
}

.bg-green {
  background-color: green;
}

.bg-gray {
  background-color: gray;
}

.after\:content-\[\'\'\]::after {
  --tw-content: "";
  content: var(--tw-content);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }

  .sm\:underline {
    text-decoration-line: underline;
  }
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

img,
video {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.accent-main-light {
  accent-color: #0db76a;
}

@keyframes scroll {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
